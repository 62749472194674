import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import BlockContent from "../../widgets/warehouses/BlockContent";
import HeaderWar from "../../widgets/header/HeaderWar";
import {resetSelectPointAction} from "../../../store/selectReducer";
import {IndexedDB} from "../../../asinc/indexeddb";


const CurrentWarehousesPage = () => {
    const dispatch = useDispatch();
    let params = useParams();
    let idWarehouses = params.idWarehouses;

    const warehousesStore = useSelector(state => state.warehouses.list);
    const selectPointStore = useSelector(state => state.selectPoint.list);
    const editWarehouses = useSelector(state => state.editWarehouses.status);
    const [blockScreen, setBlockScreen] = useState([]);
    const [titleScreen, setTitleScreen] = useState("Выбранный склад");
    const [warehousesL, setWarehousesL] = useState(true);
    const [warehousesO, setWarehousesO] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const [warehousesInfo, setWarehousesInfo] = useState({});

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const [notifyInfo, setNotifyInfo] = useState({
        error: {
            text: 'Ошибка записи в локальную базу данных, повторите отправку ещё раз.',
            class: 'error_notify',
            state: false
        },
        success: {
            text: 'Выбранные позиции отправлены на перемещение',
            class: 'success_notify',
            state: false
        }
    });

    useEffect(() => {
        // Сбросим ранее выбранные пункты на отправку из другого хранилища
        dispatch(resetSelectPointAction())
        // Получим текущий склад
        warehousesStore.map(warehouses => {
            if (idWarehouses === warehouses.guid) {
                // Запишем текущую информацию о выбранном складе
                setWarehousesInfo(warehouses);
                setTitleScreen(warehouses.name);
                // Личный склад = true
                setWarehousesL(warehouses.nominal);
                // Оптовы склад = true
                setWarehousesO(warehouses.wholesale);

                // Проведём группировку по блокам
                let itemsBlock = [];

                if (warehouses.orderItems !== undefined && warehouses.orderItems !== []) {
                    warehouses.orderItems.map(items => {
                        if (items.analytic === "") {
                            items.analytic = "Другое";
                        }
                        if (!itemsBlock.includes(items.analytic)) {
                            // Соберём уникальные блоки склада
                            itemsBlock.push(items.analytic);
                        }
                    });
                    let content = [];

                    itemsBlock.map(block => {
                        // Инициируем массив
                        content[block] = [];
                    });

                    itemsBlock.map(block => {
                        warehouses.orderItems.map(item => {
                            if (item.analytic === block) {
                                content[block].push(item);
                            }
                        })
                    })

                    setBlockScreen(content);
                }
            }
        });
    }, [warehousesStore, dispatch, idWarehouses])

    const sendDataAction = () => {
        // Получаем текущие выбранные пункты
        let points = selectPointStore;

        if (points.length > 0) {

            // Документ для отправки
            let document = {};

            // Пункты с обновлённым количеством номенклатуры
            let sendingPoint = [];

            // eslint-disable-next-line array-callback-return
            points.map(point => {
                if (point.data.analytic === "Другое") {
                    point.data.analytic = "";
                }

                sendingPoint.push({
                    item: {
                        guid: point.data.item.guid,
                        name: point.data.item.name,
                        unit: point.data.item.unit,
                    },
                    quantity: Number(point.num),
                    analytic: point.data.analytic
                });
            });

            // Сформируем и запишем документ на отправку
            document.stocks = [];
            // todo: добавить проверку направления перемещения
            document.stocks.push({
                guid: warehousesInfo.guid,
                name: warehousesInfo.name,
                nominal: warehousesInfo.nominal,
                wholesale: warehousesInfo.wholesale,
                items: sendingPoint
            })

            console.log("document", document);

            IndexedDB().then(db => {
                // Запустим транзакцию базы данных и получите хранилище объектов online_sends
                let tx = db.transaction(['warehouses_sends'], 'readwrite');
                let store = tx.objectStore('warehouses_sends');
                // Добавим готовый к отправке документ в хранилище
                store.add(document);
                // Ожидаем завершения транзакции базы данных
                tx.oncomplete = () => {
                    // Формируем уведомление о смене статуса документа на отправленный
                    setNotifyInfo({
                        error: {
                            text: 'Ошибка записи в локальную базу данных, повторите отправку ещё раз.',
                            class: 'error_notify',
                            state: false
                        },
                        success: {
                            text: 'Выбранные позиции отправлены на перемещение',
                            class: 'success_notify',
                            state: true
                        }
                    });

                    setTimeout(function () {
                        setNotifyInfo({
                            error: {
                                text: 'Ошибка записи в локальную базу данных, повторите отправку ещё раз.',
                                class: 'error_notify',
                                state: false
                            },
                            success: {
                                text: 'Чек-лист сохранён',
                                class: 'success_notify',
                                state: false
                            }
                        });
                        // Возвращаемся назад к списку складов
                        window.history.back();
                    }, 4000);
                }
                tx.onerror = (event) => {
                    console.log('Ошибка записи документа на отправку' + event.target.errorCode);
                    setNotifyInfo({
                        error: {
                            text: 'Ошибка записи в локальную базу данных, повторите отправку ещё раз.',
                            class: 'error_notify',
                            state: true
                        },
                        success: {
                            text: 'Выбранные позиции отправлены на перемещение',
                            class: 'success_notify',
                            state: false
                        }
                    });
                    setTimeout(function () {
                        setNotifyInfo({
                            error: {
                                text: 'Ошибка записи в локальную базу данных, повторите отправку ещё раз.',
                                class: 'error_notify',
                                state: false
                            },
                            success: {
                                text: 'Чек-лист сохранён',
                                class: 'success_notify',
                                state: false
                            }
                        })
                    }, 4000);
                }
            });
        } else {
            setNotifyInfo({
                error: {
                    text: 'Выберите пункты для переноса',
                    class: 'error_notify',
                    state: true
                },
                success: {
                    text: 'Выбранные позиции отправлены на перемещение',
                    class: 'success_notify',
                    state: false
                }
            });
            setTimeout(function () {
                setNotifyInfo({
                    error: {
                        text: 'Выберите пункты для переноса',
                        class: 'error_notify',
                        state: false
                    },
                    success: {
                        text: 'Чек-лист сохранён',
                        class: 'success_notify',
                        state: false
                    }
                })
            }, 4000);
        }

    }

    const sendDataAction_main = () => {
        // Получаем текущие выбранные пункты
        let points = selectPointStore;

        if (points.length > 0) {

            // Документ для отправки
            let document = {};

            // Пункты с обновлённым количеством номенклатуры
            let sendingPoint = [];

            // eslint-disable-next-line array-callback-return
            points.map(point => {
                if (point.data.analytic === "Другое") {
                    point.data.analytic = "";
                }

                sendingPoint.push({
                    item: {
                        guid: point.data.item.guid,
                        name: point.data.item.name,
                        unit: point.data.item.unit,
                    },
                    quantity: Number(point.num),
                    analytic: point.data.analytic
                });
            });

            // Сформируем и запишем документ на отправку
            document.stocks = [];
            // todo: добавить проверку направления перемещения
            document.stocks.push({
                guid: warehousesInfo.guid,
                name: warehousesInfo.name,
                nominal: true,
                wholesale: true,
                items: sendingPoint
            })

            console.log("document", document);

            IndexedDB().then(db => {
                // Запустим транзакцию базы данных и получите хранилище объектов online_sends
                let tx = db.transaction(['warehouses_sends'], 'readwrite');
                let store = tx.objectStore('warehouses_sends');
                // Добавим готовый к отправке документ в хранилище
                store.add(document);
                // Ожидаем завершения транзакции базы данных
                tx.oncomplete = () => {
                    // Формируем уведомление о смене статуса документа на отправленный
                    setNotifyInfo({
                        error: {
                            text: 'Ошибка записи в локальную базу данных, повторите отправку ещё раз.',
                            class: 'error_notify',
                            state: false
                        },
                        success: {
                            text: 'Выбранные позиции отправлены на перемещение',
                            class: 'success_notify',
                            state: true
                        }
                    });

                    setTimeout(function () {
                        setNotifyInfo({
                            error: {
                                text: 'Ошибка записи в локальную базу данных, повторите отправку ещё раз.',
                                class: 'error_notify',
                                state: false
                            },
                            success: {
                                text: 'Чек-лист сохранён',
                                class: 'success_notify',
                                state: false
                            }
                        });
                        // Возвращаемся назад к списку складов
                        window.history.back();
                    }, 4000);
                }
                tx.onerror = (event) => {
                    console.log('Ошибка записи документа на отправку' + event.target.errorCode);
                    setNotifyInfo({
                        error: {
                            text: 'Ошибка записи в локальную базу данных, повторите отправку ещё раз.',
                            class: 'error_notify',
                            state: true
                        },
                        success: {
                            text: 'Выбранные позиции отправлены на перемещение',
                            class: 'success_notify',
                            state: false
                        }
                    });
                    setTimeout(function () {
                        setNotifyInfo({
                            error: {
                                text: 'Ошибка записи в локальную базу данных, повторите отправку ещё раз.',
                                class: 'error_notify',
                                state: false
                            },
                            success: {
                                text: 'Чек-лист сохранён',
                                class: 'success_notify',
                                state: false
                            }
                        })
                    }, 4000);
                }
            });
        } else {
            setNotifyInfo({
                error: {
                    text: 'Выберите пункты для переноса',
                    class: 'error_notify',
                    state: true
                },
                success: {
                    text: 'Выбранные позиции отправлены на перемещение',
                    class: 'success_notify',
                    state: false
                }
            });
            setTimeout(function () {
                setNotifyInfo({
                    error: {
                        text: 'Выберите пункты для переноса',
                        class: 'error_notify',
                        state: false
                    },
                    success: {
                        text: 'Чек-лист сохранён',
                        class: 'success_notify',
                        state: false
                    }
                })
            }, 4000);
        }

    }


    if (blockScreen !== undefined && blockScreen !== []) {
        return (
            <>
                <HeaderWar filter={true} title={titleScreen}/>

                <div className="screenBlock">

                    <div className="usageBlockWrapper">
                        <h4>Часто перемещаемые позиции</h4>
                        <input
                            className="tgl tgl-ios"
                            id="cb2"
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        <label className="tgl-btn" htmlFor="cb2"></label>
                    </div>

                    {!editWarehouses &&
                        <>
                            <hr/>
                            <br/>
                            <h3 className="editTitleActive">Режим выбора номенклатуры<br/> для перемещения</h3>

                        </>
                    }

                    {editWarehouses &&
                        <>
                            <hr/>
                            <br/>
                            <h3 className="editTitleActive">Режим выбора количества</h3>

                        </>
                    }


                    <BlockContent data={blockScreen} openUsage={isChecked}/>

                    {notifyInfo.success.state &&
                        <div className={"btnSaveWrapper " + notifyInfo.success.class}>
                            <div className="notifySuccess">
                                {notifyInfo.success.text}
                            </div>
                        </div>
                    }
                    {notifyInfo.error.state &&
                        <div className={"btnSaveWrapper " + notifyInfo.error.class}>
                            <div className="notifyError">
                                {notifyInfo.error.text}
                            </div>
                        </div>
                    }
                    {!editWarehouses &&
                        <>
                            {(!warehousesL && !warehousesO) &&
                                <>
                                    <div
                                        className="btn sendBtn sendBtnLW btn-block btn-lg-w"
                                        onClick={() => sendDataAction()}
                                    >
                                        <>Перенести на личный склад</>
                                    </div>
                                    <div
                                        className="btn sendBtn btn-block btn-lg-w"
                                        onClick={() => sendDataAction_main()}
                                    >
                                        <>Перенести на основной склад</>
                                    </div>
                                </>
                            }
                            {(warehousesL || warehousesO) &&
                                <div
                                    className="btn sendBtn btn-block btn-lg-w"
                                    onClick={() => sendDataAction()}
                                >
                                    <>Перенести на основной склад</>
                                </div>
                            }
                        </>
                    }
                </div>
            </>
        )
    }
}

export default CurrentWarehousesPage;
